import React from "react";
import "./Home.scss";
import BgImg from "./heroImg.png";

import { motion } from "framer-motion";

import { Link } from "react-router-dom";

function Home() {
  return (
    <motion.div
      className="home"
      style={{ backgroundImage: `url(${BgImg})` }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: 0.2,
        duration: 1.4,
      }}
    >
      <div className="home-hero">
        <motion.p
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8, duration: 1 }}
          className="home-hero_head"
        >
          Share Your <span>Vision</span> <br />
          Shape the <span>Future</span>.
        </motion.p>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5, duration: 1 }}
          className="home-hero_text"
        >
          Your Voice Matters! Share your ideas and thoughts with us to help{" "}
          <br />
          shape the future of our company and services.
        </motion.p>
        <div className="home-hero_btn">
          <Link to="/vote">
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 2, duration: 1 }}
              className="home-hero_btn-button1"
            >
              Vote
            </motion.button>
          </Link>
          <Link to="/submit">
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 2.2, duration: 1 }}
              className="home-hero_btn-button2"
            >
              Share your idea
            </motion.button>
          </Link>
        </div>
      </div>
    </motion.div>
  );
}

export default Home;
