import React, { useState } from "react";
import "./Vote.scss";
import { Link } from "react-router-dom";
import Card from "../../components/Card/Card";
import Submission from "../../data";
import { motion, AnimatePresence } from "framer-motion";
import Popup from "../../components/Popup/Popup";
import Modal from "react-modal";
import axios from "axios";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function Vote() {

  const url = "http://localhost:4000/api/data";
  
  axios.get(url)

  const sortedData = Submission.sort(function (a, b) {
    return b.id - a.id;
  });

  const listItemContainerVariant = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: { staggerChildren: 0.3, delayChildren: 1 },
    },
  };
  const listItemVariant = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: { type: "spring", stiffness: 120 },
    },
    exit: { scale: 1.1, x: 500, opacity: 0 },
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [voteId, setVoteId] = useState();

  function openModal(id) {
    setVoteId(id);

    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
    setVoteId();
  }

  return (
    <div className="vote">
      <div className="vote-head">
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
          className="vote-head_greet"
        >
          Show your <span>Reaction</span>, <br />
          to take the <span>Action</span>.
        </motion.p>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 20, duration: 1 }}
          className="vote-head_external"
        >
          Wanna publish your idea or <br />
          suggestion?
          <br />
          <Link to="/submit" className="vote-head_external-link">
            Click Here
          </Link>
        </motion.p>
      </div>
      <div className="vote-data">
        <AnimatePresence>
          <motion.ul
            variants={listItemContainerVariant}
            initial="hidden"
            animate="show"
          >
            {sortedData.map((data, i) => (
              <motion.li
                key={data.id}
                variants={listItemVariant}
                viewport={{ once: true }}
                onClick={() => openModal(data.id)}
              >
                <Card
                  key={data.id}
                  name={data.name}
                  type={data.type}
                  date={data.date}
                  idea={data.idea}
                  like={data.likes}
                  dislike={data.dislikes}
                />
              </motion.li>
            ))}
          </motion.ul>
        </AnimatePresence>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <Popup id={voteId} />
      </Modal>
    </div>
  );
}

export default Vote;
