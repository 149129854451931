const Submission = [
  {
    id: 1,
    name: "first one",
    type: "Idea",
    idea: "just a sample idea just a sample idea,just a sample idea,just a sample idea,just a sample idea,just a sample idea",
    date: "16-10-2023",
    likes: 8,
    dislikes: 0,
  },
  {
    id: 2,
    name: "unknown",
    type: "Complaint",
    idea: "just a sample idea just a sample idea,just a sample idea,just a sample idea,just a sample idea,just a sample idea",
    date: "16-10-2023",
    likes: 8,
    dislikes: 0,
  },
  {
    id: 3,
    name: "unknown",
    type: "Suggestion",
    idea: "just a sample idea just a sample idea,just a sample idea,just a sample idea,just a sample idea,just a sample idea",
    date: "16-10-2023",
    likes: 8,
    dislikes: 0,
  },
  {
    id: 4,
    name: "unknown",
    type: "Appreciation",
    idea: "just a sample idea just a sample idea,just a sample idea,just a sample idea,just a sample idea,just a sample idea",
    date: "16-10-2023",
    likes: 8,
    dislikes: 0,
  },
  {
    id: 5,
    name: "unknown",
    type: "Suggestion",
    idea: "just a sample idea just a sample idea,just a sample idea,just a sample idea,just a sample idea,just a sample idea",
    date: "16-10-2023",
    likes: 8,
    dislikes: 0,
  },
  {
    id: 6,
    name: "unknown",
    type: "Complaint",
    idea: "just a sample idea just a sample idea,just a sample idea,just a sample idea,just a sample idea,just a sample idea",
    date: "16-10-2023",
    likes: 8,
    dislikes: 0,
  },
  {
    id: 7,
    name: "unknown",
    type: "Idea",
    idea: "just a sample idea just a sample idea,just a sample idea,just a sample idea,just a sample idea,just a sample idea",
    date: "16-10-2023",
    likes: 8,
    dislikes: 0,
  },
  {
    id: 8,
    name: "8th",
    type: "Appreciation",
    idea: "just a sample idea just a sample idea,just a sample idea,just a sample idea,just a sample idea,just a sample idea",
    date: "16-10-2023",
    likes: 8,
    dislikes: 0,
  },

  {
    id: 10,
    name: "10th one",
    type: "Suggestion",
    idea: "just a sample idea just a sample idea,just a sample idea,just a sample idea,just a sample idea,just a sample idea",
    date: "16-10-2023",
    likes: 4,
    dislikes: 10,
  },
];

export default Submission;
