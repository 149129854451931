import React, { useState } from "react";
import "./Card.scss";

import { animate, motion } from "framer-motion";

function Card(props) {
  const [like, setLike] = useState(0);

  return (
    <div className="card" key={props.id}>
      <div className="wrapper">
        <div className="card-header">
          <p>{props.type}</p>

          <p>{props.date}</p>
        </div>
        <div className="card-body">
          <div className="card-type">
            <p>{props.name}</p>
          </div>
          <div className="card-idea">
            <p>{props.idea}</p>
          </div>
        </div>
      </div>
      <div className="card-reactions">
        <div className="card-reactions_like">
          <span className="likes count">{props.like}</span>
          <img
            src={process.env.PUBLIC_URL + "/assets/img/loved-emoji.png"}
            alt=""
          />
        </div>
        <div className="card-reactions_dislike">
          <span className="dislike count">{props.dislike}</span>
          <img
            src={process.env.PUBLIC_URL + "/assets/img/neutral-emoji.png"}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Card;
