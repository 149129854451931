import React, { useState } from "react";
import "./Submit.scss";
import axios from "axios";
import { motion, useMotionValue, useTransform } from "framer-motion";

import Statue from "./statue.png";

function Submit() {
  const x = useMotionValue(200);
  const y = useMotionValue(200);

  const rotateX = useTransform(y, [0, 400], [20, -20]);
  const rotateY = useTransform(x, [0, 400], [-20, 20]);

  function handleMouse(event) {
    const rect = event.currentTarget.getBoundingClientRect();
    x.set(event.clientX - rect.left);
    y.set(event.clientY - rect.top);
  }

  const url = "http://localhost:4000/api/data";

  const [data, setData] = useState({
    type: "",
    name: "",
    message: "",
  });

  console.log(data.type);

  function submit(e) {
    e.preventDefault();

    axios
      .post(url, {
        name: data.name,
        type: data.type,
        message: data.message,
      })
      .then((res) => {
        console.log(res.data);
      });
  }

  function handle(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log(newData);
  }

  return (
    <div className="submit">
      <div className="submit-head">
        Share your <span>Ideas</span>, <span>Suggestion</span>, <br />
        <span>Appreciations</span> and <span>Complaints</span>.
      </div>
      <motion.div className="submit-body" onMouseMove={handleMouse}>
        <form onSubmit={(e) => submit(e)}>
          <div className="submit-form">
            <p className="submit-form_text">Fill out the fields</p>
            <select name="type" id="type" onChange={(e) => handle(e)}>
              <option value="idea">Idea</option>
              <option value="suggestion">Suggestion</option>
              <option value="appreciations">Appreciations </option>
              <option value="complaints">Complaints </option>
            </select>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Enter your name"
              onChange={(e) => handle(e)}
              value={data.name}
            />
            <textarea
              name="message"
              id="message"
              cols="30"
              rows="5"
              placeholder="Type here"
              onChange={(e) => handle(e)}
              value={data.message}
            ></textarea>
            <div className="submit-checkbox">
              <label className="checkbox-container">
                Do you wanna make it private?
                <input type="checkbox" id="private" name="private" />
                <span className="checkmark"></span>
              </label>
            </div>
            <button type="sumit" id="submit">
              Submit
            </button>
          </div>
        </form>
        <div className="submit-banner">
          <motion.img src={Statue} style={{ rotateX, rotateY }} alt="" />
        </div>
      </motion.div>
    </div>
  );
}

export default Submit;
