import React from "react";
import "./Navbar.scss";
import { NavLink } from "react-router-dom";
import Logo from "./logo.png";

import { motion } from "framer-motion";

function Navbar() {
  return (
    <div className="navbar">
      <ul>
        <motion.li
          initial={{ opacity: 0, x: -100 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            delay: 1.2,
            duration: 1,
          }}
        >
          <NavLink to="/" exact>
            Home
          </NavLink>
        </motion.li>
        <motion.li
          initial={{ opacity: 0, x: -100 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            delay: 1.1,
            duration: 1,
          }}
        >
          <NavLink to="/vote">Vote</NavLink>
        </motion.li>
        <li>
          <NavLink to="/">
            <motion.img
              initial={{ opacity: 0, rotate: 360 }}
              animate={{ rotate: 0, opacity: 1 }}
              transition={{
                delay: 1,
                duration: 2,
                type: "spring",
                damping: 12,
                mass: 2,
              }}
              src={Logo}
              alt=""
            />
          </NavLink>
        </li>
        <motion.li
          initial={{ opacity: 0, x: 100 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            delay: 1.1,
            duration: 1,
          }}
        >
          <NavLink to="/submit">Submit Idea</NavLink>
        </motion.li>
        <motion.li
          initial={{ opacity: 0, x: 100 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            delay: 1.2,
            duration: 1,
          }}
        >
          <NavLink to="/popup">Visit our Website</NavLink>
        </motion.li>
      </ul>
    </div>
  );
}

export default Navbar;
