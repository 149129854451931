import React, { useState, useEffect } from "react";
import "./Popup.scss";
import Like from "./loved-emoji.png";
import Dislike from "./neutral-emoji.png";

import Submission from "../../data";

function Popup(props) {
  const idToFilter = props.id;
  const filteredObjects = Submission.filter((item) => item.id === idToFilter);

  return (
    <div className="popup">
      {filteredObjects.map((item, index) => (
        <div className="popup-head" key={props.id}>
          <p className="popup-head_text">
            <span className="popup-highlight">{item.type}</span>
            &nbsp;from <br /> <span className="popup-bold">{item.name}</span>
          </p>
          <p className="popup-idea">{item.idea}</p>
          <p></p>
          <div className="popup-value">
            <p className="popup-like">
              {item.likes}
              <img src={Like} alt="" />
            </p>
            <p className="popup-dislike">
              {item.dislikes} <img src={Dislike} alt="" />
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Popup;
