import React from "react";
import "./Footer.scss";

function Footer() {
  return (
      <div className="footer">
          <p>Powered by</p>
      <img src={process.env.PUBLIC_URL + "/assets/img/logo.png"} alt="" />
    </div>
  );
}

export default Footer;
