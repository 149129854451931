import "./App.scss";
import Home from "./Page/Home/Home";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Submit from "./Page/Submit/Submit";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Vote from "./Page/Vote/Vote";
import Popup from "./components/Popup/Popup";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Switch>
          <div className="container">
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/submit">
              <Submit />
            </Route>
            <Route path="/vote">
              <Vote />
            </Route>
            <Route path="/popup">
              <Popup />
            </Route>
          </div>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
